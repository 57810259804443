<script setup>
import { onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
import ODataLookup from 'o365.vue.components.DataLookup.vue'

const props = defineProps({
    sjaID: String,
    readOnly: Boolean
});

const dsReasons = getOrCreateDataObject({
    id: `dsReasons_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJAReasons',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJAReasons',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'Comment', },
        { name: 'ReasonType_ID', },
        { name: 'ReasonType', },
        { name: 'ReasonTypeDescription', },
        { name: 'ReasonTypeSortOrder', sortOrder: 1, sortDirection: 'asc' },
        { name: 'NumberAndReasonType', }
    ],
    whereClause: "SJA_ID = " + props.sjaID
});

const dsReasonsTypes = getOrCreateDataObject({
    id: `dsReasonsTypes_${props.sjaID}`,
    maxRecords: 25,
    viewName: 'atbv_SJA_ReasonTypes',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Description', },
        { name: 'SortOrder', },
        { name: 'OrgUnit_ID', }
    ],
});

async function addReason(row) { 
    await dsReasons.createNew({
        SJA_ID: props.sjaID,
        ReasonType_ID: row.ID
    });
}

onMounted(() => {
    dsReasons.load();
    dsReasonsTypes.load();
})

dsReasons.on("AfterSave", async function (options, row) {
    await dsReasons.load();
});

</script>

<template>
    <ODataLookup v-if="!props.readOnly" :data-object="dsReasonsTypes" :bind="sel => { addReason(sel) }">
        <OColumn :headerName="$t('Sort Order')" field="SortOrder" width="80"></OColumn>
        <OColumn :headerName="$t('Name')" field="Name" width="150"></OColumn>
        <OColumn :headerName="$t('Description')" field="Description" width="250"></OColumn>
        <template #target="scope" @click="scope.open">
            <button :ref="scope.target"  class="p-0 btn btn-link">
                <div class="d-flex" :title="$t('Add a reason for the SJA being performed')">
                    <h5>{{ $t('Reasons') }}</h5> 
                    <i class="mx-1 bi bi-plus-circle-fill"></i>
                </div>
            </button>
        </template>
    </ODataLookup>
    <div v-if="props.readOnly" class="d-flex">
        <h5>{{ $t('Reasons') }}</h5>
    </div>
    <hr class="m-0" />

    <div class="d-flex flex-wrap mt-2">
        <div v-for="(reason, index) in dsReasons.data" :key="index">
            <div v-if="reason.ReasonType != null" class="d-flex me-3">
                <span>
                    {{ reason.NumberAndReasonType }}
                </span>
                <div>
                    <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsReasons" :row="reason" class="btn btn-sm btn-link p-0" :title="$t('Delete reason')" style="-bs-btn-line-height: 1;">
                                <i class="bi bi-x-circle ms-2"></i>
                    </OActionDelete>
                </div>
            </div>
        </div>
    </div>
</template>